export default [
  {
    path: '/learn/codewars',
    title: 'Phone Number'
  },
  {
    path: '/learn/codewars/rgb-to-hex',
    title: 'RGB to Hex'
  },
  {
    path: '/learn/codewars/remove-string-spaces',
    title: 'Remove String Spaces'
  },
  {
    path: '/learn/codewars/vowel-count',
    title: 'Vowel Count'
  }
];