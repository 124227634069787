<template>
  <a class="btn-blue" @click="route()"><i class="ml-3"></i> Back</a>
</template>

<script>
import { goTo } from '@/scripts/routeHelper.js'

export default {
  props: [ 'path' ],
  methods: {
    route: function() { goTo(this.path) },
  }
}
</script>

<style scoped>
i {
  border: solid white;
  border-width: 0 2px 2px 0px;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-bottom: 1px;
}
</style>
